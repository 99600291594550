import React from 'react';
import '../../../manager/fileList/file/file.css';
import dirLogo from '../../../../assets/img/dir.svg';
import fileLogo from '../../../../assets/img/file.svg';
import { useDispatch, useSelector } from 'react-redux';
import { pushToStack, setCurrentDir } from '../../../../reducers/fileReducer';
import { deleteFile, acceptFile, rejectFile, downloadFile} from '../../../../actions/file'
import sizeFormat from '../../../../utils/sizeFormat';
import { NavLink } from 'react-router-dom';


const File = ({file}) => {

  const dispatch = useDispatch()
  const currentDir = useSelector(state => state.files.currentDir)
  const fileView = useSelector(state => state.files.view )


  function openDirHandler(){
    if(file.type === 'dir'){
      dispatch(pushToStack(currentDir))     
      dispatch(setCurrentDir(file._id))       
    }
  }

  function downloadClickHandler(e){
    e.stopPropagation()
    downloadFile(file)
  }

  function deleteClickHandler(e){
    e.stopPropagation()    
    dispatch(deleteFile(file)) 
  }

  function acceptClickHandler(e){
    e.stopPropagation()    
    dispatch(acceptFile(file)) 
  }

  function rejectClickHandler(e){
    e.stopPropagation()    
    dispatch(rejectFile(file)) 
  }

  if(fileView==='plate')
    {
      const foldericon = file.type === 'dir'? dirLogo : fileLogo
      //const foldericonpath = '/cloud'+foldericon
      return (        
        <div className="file-plate" onClick={() => openDirHandler()}>
            <div className="file-plate__icon">
                
                <img src={foldericon} alt="Rubicon Server Page" className="file__img"/>
            </div>
            <div className="file-plate__name">{file.name}</div>  
            <div className="file-plate__btns">
              {file.type!=="dir" && <button onClick={(e) => downloadClickHandler(e)} className="file-plate__btn file__download">DownLoad</button>}
              <button onClick={(e)=> deleteClickHandler(e)} className="file-plate__btn file__delete">Delete</button>
            </div>            
        </div>
      )

    }

    if(fileView==='list')
    {
        const foldericon = file.type === 'dir'? dirLogo : fileLogo
        let status
        switch(file.status.toLowerCase()){
          case "new":
            status = "новый"
          break;
          case "accepted":
            status = "принят"
          break;
          case "rejected":
            status = "отклонён"
          break;
          case "sent":
            status = "на проверке"
          break;
          default:
            status = "статус"
        }
        //const foldericonpath = '/cloud'+ foldericon
      return (        
        //<div className="file" onClick={file.type === 'dir' ? ()=> openDirHandler() : ''}>
        <div className="file" onClick={() => openDirHandler()}>
            <div className="file__icon">
                <img src={foldericon} alt="Rubicon Server Page" className="file__img"/>
            </div>            
            <div className="file__name">{file.name}</div>
            {/* <div className="file__author">{file.user.slice(0,7)}...{file.username}</div>             */}
            <div className="file__author"><NavLink to={"/users/"+file.user}>{file.username}</NavLink></div>            
            
            <div className="file__status">
              <div className={file.status.toLowerCase()}>{status}</div>
              {file.status.toLowerCase()==="sent" && <button title='Принять файл' onClick={(e)=> acceptClickHandler(e)} className="file__btn file__accept"><span></span>Принять</button>}
            </div>
            <div className="file__date">{file.date.slice(0,10)}</div>
            <div className="file__size">{sizeFormat(file.size)}</div>
            {file.status.toLowerCase()==="sent" && <button title='Отклонить файл' onClick={(e)=> rejectClickHandler(e)} className="file__btn file__reject"><span></span>Отклонить</button>}
            {file.type!=="dir" && <button title='Сохранить на диск' onClick={(e) => downloadClickHandler(e)} className="file__btn file__download"><span></span>Скачать</button>}
        </div>
      )
    }
}

export default File