import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFiles, getAllFiles, createDir, uploadFile, searchFiles, getFilesCount } from '../../actions/file';
import { showPreloader } from '../../reducers/appReducer';
import { popStack, setCurrentDir, setFileView, setPopupDisplay } from '../../reducers/fileReducer';
import { getPagesCount, getEnding } from '../../utils/page';
import FileList from '../disk/fileList/FileList';
import Pagination from '../UI/pagination/Pagination';
import './disk.css';
import Popup from './Popup';
import Uploader from './uploader/Uploader';


const Disk = () => {
    const dispatch = useDispatch()
    const currentDir = useSelector(state => state.files.currentDir)
    const totalCount = useSelector(state => state.files.totalCount)

    const loader = useSelector(state => state.app.loader)

    const [dragEnter, setDragEnter] = useState(false)
    const [sort, setSort] = useState('date')

    const [searchName, setSearchName] = useState('')
    const [searchTimeout, setSearchTimeout] = useState(false)

    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const isAuth = useSelector(state => state.user.isAuth)  

 

    //const userId = "6321db073bb079c9e2368859"
    
    useEffect(() =>{
        // сюда передаём ещё номер страницы @page и количество результатов на странице @limit
        dispatch(getFilesCount());
        setTotalPages(getPagesCount(totalCount, limit)); 
        dispatch(getFiles(currentDir, sort, page, limit));
        
        
        

        //console.log("Counter: " + totalPages);

        //dispatch(getAllFiles(sort))
    }, [currentDir, sort, totalCount, totalPages])

    function showPopupHandler(){        
        dispatch(setPopupDisplay('flex'));
        //console.log(document.getElementById('popup_createdir'))
    }

    function backClickHandler(){                
        dispatch(popStack())        
    }

    function fileUploadHandler(event){
        const files = [...event.target.files];

        
        //alert(fileExt);        
        //console.log(files[0].name.split("."));

        // Можете быть даже тут нужно проверять есть ли уже такой файл у нас в базе (на клиенте) и если да
        // тогда Выводить сообщение, что "такой файл" (файл с таким именем уже существует). Без запроса в Базу Данных.

        // тут НИЖЕ нужно ПЕРЕДЕЛАТЬ проверку в цикле по всем выбранным файлам
        // проверять расширение и наличие пристутсвия такого файла в "состоянии"

        // if(fileExt=="zip" || fileExt=="rar"){
        //     files.forEach(file => dispatch(uploadFile(file, currentDir)))
        // }
        // else{
        //     alert("Вы выбрали неверный файл. Должен быть архив (*.rar или *.zip). Пожалуйста, попробуйте ещё раз.");
        // }

        files.forEach(file => {
            let fileExt = files[0].name.split(".").pop();
            //alert(file.name);

            if(fileExt=="zip" || fileExt=="csv"){
                dispatch(uploadFile(file, currentDir));
            }
            else{
                alert(`Выбраный файл ${file.name} имеет неверный формат. Должен быть архив (*.csv или *.zip). Пожалуйста, попробуйте снова.`);
            }
            //dispatch(uploadFile(file, currentDir))
        })

        
        
    }

    function DragEnterHandler(event){
        event.preventDefault()
        event.stopPropagation()
        setDragEnter(true)
    }

    function DragLeaveHandler(event){
        event.preventDefault()
        event.stopPropagation()
        setDragEnter(false)
    }

    function dropHandler(event){
        event.preventDefault()
        event.stopPropagation()
        let files = [...event.dataTransfer.files]
        console.log(files);
        files.forEach(file => dispatch(uploadFile(file, currentDir)))
        setDragEnter(false)
    }

    function searchChangeHandler(e){
        setSearchName(e.target.value)  
        if(searchTimeout!==false){
            clearTimeout(searchTimeout)
        }
        //dispatch(showPreloader())
        if(e.target.value!=="")
        {
            setSearchTimeout(setTimeout((value)=>{
            dispatch(searchFiles(value.toLowerCase()))
            }, 50, e.target.value))  
            
        } else {
            dispatch(getFiles(currentDir, sort, page, limit));
        }        
    }

    const changePage = (page) => {
        setPage(page);
        //alert(page);
        dispatch(getFiles(currentDir, sort, page, limit));
        //fetchPosts(limit, page)
    }

    
    

    if(loader === true){
        return (
            <div className="loader">
               <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
    
    return (!dragEnter ?
        <div className="disk" onDragEnter={DragEnterHandler} onDragLeave={DragLeaveHandler} onDragOver={DragEnterHandler}>
            <div className="disk__btns">
                {currentDir &&
                    <button className="disk__back" onClick={() => backClickHandler()}>Назад</button>
                }
                <button className="disk__create" onClick={() => showPopupHandler()}>Создать папку</button>
                
                <div className="disk__upload">
                    <form encType="multipart/form-data;charset=UTF-8">
                        <label htmlFor="disk__upload_input" className="disk__upload_label"><div></div>Загрузить файл(ы)</label>
                        <input multiple onChange={(event) => fileUploadHandler(event)} type="file" id="disk__upload_input" className="disk__upload_input"  accept=".zip, .csv" />
                    </form>
                </div>
                <div className='disk__search'>
                {isAuth && <div className='navbar__searchbar'>
                Поиск: <input 
                value={searchName} 
                onChange={e => searchChangeHandler(e)} 
                onFocusCapture={e => console.log("Searching...")}
                className='navbar__search' 
                type="text" 
                placeholder="Название файла..."/></div>}

                </div>
                <div className='disk__sort'>Сортировать:  
                    <select 
                        className="disk__select" 
                        value={sort} 
                        onChange={(e) => setSort(e.target.value)}>
                        <option value="date">По дате</option>
                        <option value="status">По статусу</option>
                        <option value="name">По имени</option>                        
                        <option value="type">По типу</option>
                    </select>
                </div>
                <div className='disk__view'>
                    <button onClick={() => dispatch(setFileView('plate'))} className="disk__plate"></button> 
                    <button onClick={() => dispatch(setFileView('list'))} className="disk__list"></button>
                </div>
            </div>
            <FileList />
            <Popup/>
            <Uploader/>
            Всего в системе: {totalCount} {getEnding(totalCount, ['файл', 'файла', 'файлов'])}
            
            {/* здесь добавляем компонент Пагинация Pagination (который выводит страницы со ссылками) */}
            {/* Страницы: {totalCount/5}             */}
            <Pagination 
                page={page}                 
                changePage={changePage} 
                totalPages={totalPages} 
            />

        </div> 
        :
        <div className="drop-area" onDrop={dropHandler} onDragEnter={DragEnterHandler} onDragLeave={DragLeaveHandler} onDragOver={DragEnterHandler}>
            Перетащите файлы сюда 
        </div>

    )
}

export default Disk;