import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import { hidePreloader, showPreloader } from '../../reducers/appReducer';
import {API_URL} from "../../config";

import './balance.css';

const Balance = () => {
    const dispatch = useDispatch();

    const [userId, setUserId] = useState("6322ffbf66f6c7abd4ae6b95")
    const [balance, setBalance] = useState()
    const [user, setUser] = useState({})
    
    const loader = useSelector(state => state.app.loader)

    //Реализация через async + await => then()

    // async function getInfo(user_id) {
    //     try {
    //         dispatch(showPreloader());

    //         await axios.get(`${API_URL}api/users/info`,  {
    //             params: {user_id : user_id},                      
    //                 headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
    //             }).then(function(response){
    //                 setBalance(response.data.balance)                
    //                 setUser(response.data) 
    //         })                
    //     } catch (e) {
    //         console.log(e);
    //     } finally {
    //         dispatch(hidePreloader());      
    //     }      
    // }

    //Реализация через Promise и Resolve => .then()

    function getInfo(user_id) {
        const p = new Promise(function (resolve, reject) {
            dispatch(showPreloader());
            
            const response = axios.get(`${API_URL}api/users/info`,  {
            params: {user_id : user_id},                      
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
            })   
            //response.modified = true                     
            resolve(response)
            return response
        })        
        p.then(response => {   
            //console.log(response);                 
            setBalance(response.data.balance)      
            setUser(response.data)
            
        }).catch(err => {
            console.log('Error', err)
        }).finally(() => {
            dispatch(hidePreloader());
        })       
    }


    function userBalance(user_id){        
        getInfo(user_id)
    }

    useEffect(() => {        
        getInfo(userId)
    }, [] );

    if(loader === true){
        return (
            <div className="loader">
               <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }

    return (
        <div className='balance'>            
            <h1>Страница баланс (учим Promise)</h1>
            <div className='balance__form'>
                <label htmlFor="user_id">Введите UserId</label>
                <input id='user_id' type="text" className="balance__user" onChange={(e) => setUserId(e.target.value)} value={userId} />
                <strong>Имя:</strong> {user.name}<br></br>
                <strong>Email:</strong> {user.email}<br></br>

                <strong>Баланс:</strong> {balance}$
                <button onClick={() =>userBalance(userId)}>Показать данные</button>
            </div>

        </div>
    )
}

export default Balance