import axios from 'axios'
import { setUser, updated, registred } from '../reducers/userReducer'
import { hidePreloader, showPreloader } from '../reducers/appReducer';
import {API_URL} from "../config";
import { getError } from '../reducers/appReducer';


export const registration = (name, email, password) => {
    
    return async dispatch => {
        try {            
            const params = {name, email, password}
    
            const response = await axios.post(`${API_URL}api/auth/registration`, params)        
            //dispatch(setUser(response.data.user))
            //alert(response.data.user)
            //alert(response.data.user.email)
            dispatch(registred(response.data.user))

            //alert(response.data.message + response.status + response.statusText)
            //localStorage.setItem('token', response.data.token)
            //console.log(response.data);
        } catch (e) {
            //alert(e)      
            //console.log(e.response.data.errors.errors[0].msg);
            //alert(e.response.data.message)
            if(e.response.data.message=="Uncorrect request"){
                dispatch(getError(e.response.data.errors.errors[0].msg))       
            }
            else{
                dispatch(getError(e.response.data.message))   
            }

            setTimeout(() => {
                dispatch(getError(""))                
            }, 5000);   
            //alert(e.response.data.errors.errors[0].msg);
            
            //alert(e.response.data.message)
            
        }
    }   
    
}

export async function addAdminRole(user_id){
 
    try {

        const params = {user_id}

        const response = await axios.put(`${API_URL}api/users/addadmin`,  
            params, {                     
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
            }).then(function(response){
                //loadData(response.data);
                //setUser(response.data);
                //console.log(response.data); 

                if(response.status===200){
                    //alert("Права админа!")                                
                }

                return response.data;        
         })        
        
    } catch (e) {
        console.log(e);
    }
}

export async function removeAdminRole(user_id){
 
    try {

        const params = {user_id}

        const response = await axios.put(`${API_URL}api/users/removeadmin`,  
            params, {                     
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
            }).then(function(response){
                //loadData(response.data);
                //setUser(response.data);
                //console.log(response.data); 

                if(response.status===200){
                    //alert("Убрали права админа!")                                
                }
         })        
        
    } catch (e) {
        console.log(e);
    }
}

export async function changeBalance(user_id, amount){
 
    try {

        const params = {user_id, amount}

        const response = await axios.put(`${API_URL}api/users/balance`,  
            params, {                     
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
            }).then(function(response){
                //loadData(response.data);
                //setUser(response.data);
                //console.log(response.data); 

                if(response.status===200){
                    //alert("Баланс пользователя изменён!")                                
                }
         })        
        
    } catch (e) {
        console.log(e);
    }
}


export const login = (email, password) => {
    return async dispatch => {
        try {            
            const params = {email, password}
    
            const response = await axios.post(`${API_URL}api/auth/login`, params)        
            //console.log(response.data.user);
            dispatch(setUser(response.data.user))
            dispatch(getError(""))

            localStorage.setItem('token', response.data.token)
            //console.log(response.data);
        } catch (e) {
            //console.log("New" + e);
            //alert("Здесь" + e.response.data.message)   
            dispatch(getError(e.response.data.message)) 
            setTimeout(() => {
                dispatch(getError(""))                
              }, 3500);    
        }
    }    
}

export const forgot = (email) => {
    return async dispatch => {
        try {            
            const params = {email}
    
            const response = await axios.post(`${API_URL}api/auth/forgot`, params)        
            console.log(response.data.message);
            console.log(response);
            dispatch(getError(response.data.message)) 
            //dispatch(setUser(response.data.user)) 
            setTimeout(() => {
                dispatch(getError(""))                
              }, 4500);             
            
        } catch (e) {            
            //alert("Напоминание - Ошибка " + e.response.data.message)
            //dispatch(getError(e.response.data.message))    
            dispatch(getError(e.response.data.message)) 
            setTimeout(() => {
                dispatch(getError(""))                
              }, 3500);    
        }
    }    
}

export const recovery = (link) => {  
    //console.log("AA"); 
    
    return async dispatch => {
        try {            
            dispatch(showPreloader())
            const params = {link}
    
            const response = await axios.post(`${API_URL}api/auth/recovery`, params)        
            //console.log(response.data.message);
            //console.log(response);
            //dispatch(getError(response.data.message)) 
            //dispatch(setUser(response.data.user))            
            
        } catch (e) {            
            //alert("Напоминание - Ошибка " + e.response.data.message)
            dispatch(getError(e.response.data.message)) 
            console.log(e.response);             
        }  
        finally {
            dispatch(hidePreloader())
        }
        // finally{
        //     setTimeout(() => {
        //         dispatch(getError(""))                
        //         }, 3500);

        // }      
    }    
}

export const passwordReset = (recoveryLink, password) => {
    
    return async dispatch => {
        try {            
            const params = {recoveryLink, password}
            //console.log(params);
    
            const response = await axios.post(`${API_URL}api/auth/reset`, params)        
            
            //alert(response.data.user.email)
            //dispatch(registred(response.data.user))
            dispatch(getError(response.data.message)) 
            //alert(response.data.message + response.status + response.statusText)
            
            //console.log(response.data);
        } catch (e) {
            //alert(e)      
            console.log(e.response.data.errors);
            dispatch(getError(e.response.data.message))               
        }
    }   
    
}

export const auth = () => {
    //alert();
    //this.setLoading(true);
    //добавить проверку для показа формы во время загрузки
    return async dispatch => {
        if(localStorage.getItem('token')!==null){
            try {    
                const response = await axios.get(`${API_URL}api/auth/auth`, 
                {headers:{Authorization:`Bearer ${localStorage.getItem('token')}`}}
                )
                dispatch(setUser(response.data.user))
                localStorage.setItem('token', response.data.token)

                //console.log(response.data);
            } catch (e) {
                //alert("Тут" + e)        
                localStorage.removeItem('token')
            } 
        }
        //finally {
        //     this.setLoading(false);
        // }
    }    
}

export const uploadAvatar = (file) => {    
    return async dispatch => {
        try {    
            const formData = new FormData()
            formData.append("file", file)
            //formData.append("filename", file.name)
            
            //вот тут можно обрезать картинку и добавлять её в formData 

            const response = await axios.post(`${API_URL}api/files/avatar`, formData, 
            {headers:{Authorization:`Bearer ${localStorage.getItem('token')}`}}
            )
            dispatch(setUser(response.data))            
        } catch (e) {        
            console.log(e);
        } 
        
    }    
}

export const uploadAva = (file) => {    
    return async dispatch => {
        try {                
            const formData = new FormData()

            formData.append("file", file)
            //formData.append("filename", file.name)
            
            //вот тут можно обрезать картинку и добавлять её в formData 

            const response = await axios.post(`${API_URL}api/files/avatar`, formData, 
            {headers:{Authorization:`Bearer ${localStorage.getItem('token')}`}}
            )
            dispatch(setUser(response.data))            
        } catch (e) {        
            console.log(e);
        }        
    }    
}

export const deleteAvatar = () => {    
    return async dispatch => {
        try {
            
            const response = await axios.delete(`${API_URL}api/files/avatar`, 
                {headers:{Authorization:`Bearer ${localStorage.getItem('token')}`}}
            )
            dispatch(setUser(response.data))            
        } catch (e) {        
            console.log(e);
        } 
        
    }    
}

export const updateProfile = (name, email) => {    
    
    return async dispatch => {
        try {
            const params = {name, email}
            const response = await axios.put(`${API_URL}api/files/profile`, params, 
                {headers:{Authorization:`Bearer ${localStorage.getItem('token')}`}}
            )
            //alert(response.status)
            if(response.status===200){
                //alert("Профиль обновлён успешно!")
                dispatch(updated(true))

                setTimeout(() => {
                    dispatch(updated(false))                
                }, 3500);  
            }
            dispatch(setUser(response.data))         

        } catch (e) {        
            console.log(e);
        } 
        
    }    
}

export function getUserName(user){  
    //alert(user);

    // export async function downloadFile(file){
    //     const response = await fetch(`${API_URL}api/files/download?id=${file._id}`, {
    //         headers: {
    //             Authorization: `Bearer ${localStorage.getItem('token')}`
    //         }
    //     })
    
        try {    
            const response = axios.get(`${API_URL}api/files/username?userid=${user}`, 
            {headers:{Authorization:`Bearer ${localStorage.getItem('token')}`}}
            )
            //dispatch(setUser(response.data.user))
            //localStorage.setItem('token', response.data.token)
            console.log(response.data.username);
            //alert(response.data.username)
            //alert(user);

            //return response.data;
            //return response.data.username;

            //console.log(response.data);
        } catch (e) {
            //alert("Тут" + e)        
            //localStorage.removeItem('token')
            //alert(e)
        }   
        
        
        
    
}