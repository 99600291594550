import React from 'react'

const UserRole = ({role}) => {
    //alert(Array.isArray(role)+ " " + role.length);
    //alert(role[0]);

    let Role = "Роль"
    let RoleName = "Название роли"
    let RoleClass = "Style"

    
    if(role === "USER"){
        Role = "U"
        RoleClass = "user_role"
        RoleName = "Пользователь"
    }
    if(role === "SUPERADMIN"){
        Role = "SA"
        RoleClass = "superadmin_role"
        RoleName = "СуперАдмин"
    } 
    if(role === "ADMIN"){
        Role = "A"
        RoleClass = "admin_role"
        RoleName = "Администратор"
    }  
    if(role === "SPECIALIST"){
        Role = "S"
        RoleClass = "specialist_role"
        RoleName = "Специалист"
    }       
    
    return (    
        <div title={RoleName} className={RoleClass}>{Role}</div>
  )
}

export default UserRole