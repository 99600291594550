import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
//import { getUserInfo } from '../../actions/users';
import {API_URL} from "../../config";
import './userCard.css';
//import { useFetching } from '../hooks/useFetching';
import avatarLogo from  '../../assets/img/user-avatar.svg'
import { useDispatch, useSelector } from 'react-redux';
import { hidePreloader, showPreloader } from '../../reducers/appReducer';
import UserCardRoles from './userCardRoles/UserCardRoles';
import { addAdminRole, changeBalance, removeAdminRole } from '../../actions/user';

const UserCard = () => {
    const dispatch = useDispatch();

    const params=useParams({});
    const [user, setUser] = useState({});
    const [roles, setRoles] = useState([]);
    const [balance, setBalance] = useState(0);

    //console.log(params);
    //const answer = getUserInfo(params.link);
    const user_id=params.link;

    //const [fetchUserInfo, isLoading, error]=useFetching(async (user_id) => {
        //const responce = await PostService.getById(id);
        //setPost(responce.data);
        //const response =  await axios.get(`${API_URL}api/files/count` + user_id);
        // const response =  await axios.get(`${API_URL}api/files/count`, {                
        //     headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
        // });

        // console.log(response); 
        //return response; 
        //setPost(response.data);

        // let url = `${API_URL}api/files/count`
            
        //     const response = await axios.get(url, {                
        //         headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
        //     });
    //});

    // useEffect(() =>{
    //     fetchUserInfo(params.link);        
    // }, [])

    const loader = useSelector(state => state.app.loader)

    function showRoles(roles){
        return roles;
    }

    const lst = [];  

    const loadData = (data) => {
        //lst.push(data);
        //console.log("Data " + data);
        setUser(data);    
        setRoles(data.roles);
        setBalance(data.balance);
    } 


    async function getUserInformation() {

        try {

            dispatch(showPreloader());

        const response = await axios.get(`${API_URL}api/users/info`,  {
                params: {user_id : user_id},                      
                    headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
                }).then(function(response){
                    loadData(response.data);
                    //setUser(response.data);
                    //console.log(response.data); 
             })
        //console.log(response.data);
        
        return response; 
        
            
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(hidePreloader());
            //console.log(user);
        }
        
        
        //setUser(response.data); 
       
    }

    function addAdmin(user_id){
                
        addAdminRole(user_id)

        if(!roles.includes("ADMIN")){
            //alert('Добавляем')
            setRoles([...roles, "ADMIN"]);
            //setRoles(user.roles.push("ADMIN"));
        }        
    }

    function removeAdmin(user_id){
        
        //alert(user_id);
        removeAdminRole(user_id)

        if(roles.includes("ADMIN")){
            //alert('Добавляем')
            setRoles(roles.filter(role => role !="ADMIN"));
            //setRoles(user.roles.push("ADMIN"));
        }        
    }

    function addMoney(user_id, summ){
        
        //alert(user_id);
        changeBalance(user_id, summ)
        if(balance + summ >= 0){
            setBalance(balance+summ);
        }   

        // if(roles.includes("ADMIN")){        
        //     setRoles(roles.filter(role => role !="ADMIN"));        
        // }        
    }

    
    useEffect(() => {                
        
        getUserInformation()        

        // axios.get(`${API_URL}api/users/info`,  {
        //     params: {user_id : user_id},                      
        //         headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
        //     })
        // .then(response => {
        //     setUser(response.data);        //     
        // })
        // .catch(error => {
        //     console.log(error);
        // });
    }, [] );

    const avatar = user.avatar ? `${API_URL + user.avatar}` : avatarLogo

    if(loader === true){
        return (
            <div className="loader">
               <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }

    return (    
    <div className='userCard'>
        <h1>{user.name}</h1>
        <div className='cardInfo'>            
            <div className='cardPhoto'>                
                <img src={avatar} alt="User avatar" className="userCard__avatar"/>
                <br/><h5>{params.link}</h5><br/></div>
            <div>
                <h4>{user.email}</h4>
                {/* <button title='Отправить письмо' onClick={() => addMoney(user_id, 10)} className="make_admin">Написать</button>
                <br/><br/> */}
                <h4>На счету: {balance}$</h4>
                <button title='Добавить баланс' onClick={() => addMoney(user_id, 10)} className="make_admin">Добавить 10$</button><br/>

                Добавить: <button title='Добавить 1$' onClick={() => addMoney(user_id, 1)} className="add_money">1$</button>
                <button title='Добавить 5$' onClick={() => addMoney(user_id, 5)} className="add_money">5$</button>
                <button title='Добавить 10$' onClick={() => addMoney(user_id, 10)} className="add_money">10$</button>
                <button title='Добавить 25$' onClick={() => addMoney(user_id, 25)} className="add_money">25$</button>
                <button title='Добавить 50$' onClick={() => addMoney(user_id, 50)} className="add_money">50$</button>
                <button title='Добавить 100$' onClick={() => addMoney(user_id, 100)} className="add_money">100$</button>
                <br/><br/>
                Списать: <button title='Списать 1$' onClick={() => addMoney(user_id, -1)} className="add_money">1$</button>
                <button title='Списать 5$' onClick={() => addMoney(user_id, -5)} className="add_money">5$</button>
                <button title='Списать 10$' onClick={() => addMoney(user_id, -10)} className="add_money">10$</button>
                <button title='Списать 25$' onClick={() => addMoney(user_id, -25)} className="add_money">25$</button>
                <button title='Списать 50$' onClick={() => addMoney(user_id, -50)} className="add_money">50$</button>
                <button title='Списать 100$' onClick={() => addMoney(user_id, -100)} className="add_money">100$</button>
                <br/><br/>
                {/* {roles} */}
                <UserCardRoles roles={roles} />
                {Array.isArray(user.roles) && !roles.includes("ADMIN") && 
                    <button title='Сделать администратором' onClick={() => addAdmin(user_id)} className="make_admin">Сделать админом</button>
                }
                {Array.isArray(user.roles) && roles.includes("ADMIN") && 
                    <button title='Убрать права администратора' onClick={() => removeAdmin(user_id)} className="make_admin">Убрать права админа</button>
                }
            </div>
        </div>
        
        

    </div>
    )
}

export default UserCard