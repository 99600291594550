import React, {useRef} from 'react'
import './myInput.css'

const MyInput = (props) => {

  let pass
  if(props.type==="password"){
    pass=true
  }

  const inputref=useRef();
  const pswrdbtnref=useRef();
  //alert(props.type)
  //props.type = "text"
  //alert(props.type)

  function showPass(){    
      if(inputref.current.type==="text"){
        inputref.current.type="password"        
      }
      else{
        inputref.current.type="text"
      }
      //alert(inputref.current.type)
      //alert(pswrdbtnref.current.className)

      if(pswrdbtnref.current.className==="pswrd_eye eye_show"){
        pswrdbtnref.current.className="pswrd_eye eye_hide"
        
      }
      else{
        pswrdbtnref.current.className="pswrd_eye eye_show"
      }
          
  }

  return (   
        <div style={{width:'90%', position: 'relative'}}>
        <input  style={{width:'100%', margin: '0 0 .75em 0'}} ref={inputref} className="myinput" 
          onChange={(event) => props.setValue(event.target.value)} 
          //onFocusCapture={(event) => props.showMessage("aaa")}           
          onFocusCapture={
            function (e){
              if(e.target.type==="password"){
                //console.log('Ввод пароля начат')
                //console.log(e.target.className);
                //console.log(e.target.closest(e.target.className));
                //console.log(e.target.getBoundingClientRect());

                if(document.querySelector('.login_message')!=null){
                  document.querySelector('.login_message').innerHTML=`
                    <div><b>Требования к паролю:</b><br>
                    от 8 до 15 символов<br>
                    1 большая буква<br>
                    1 маленькая буква<br>
                    1 цифра<br>
                    1 специальный символ
                    </div>
                  `
                  document.querySelector('.login_message').style.display = "block";
                }

              }              
              //console.log(e.target.placeholder);
            }
          } 
          onBlurCapture={
            function (e){
              if(e.target.type==="password"){
                //console.log('Ввод пароля окончен')
                //document.querySelector('.login_message').innerHTML="";
                if(document.querySelector('.login_message')!=null){
                  document.querySelector('.login_message').style.display = "none";
                }
              }              
              //console.log(e.target.placeholder);
            }
          }           
          id={props.id} 
          value={props.value} 
          type={props.type} 
          placeholder={props.placeholder}
          maxLength={props.maxLength}          
        />    
        {pass && <button ref={pswrdbtnref} style={{position: 'absolute', right: '5px', top: '7px'}} onClick={showPass} className='pswrd_eye eye_show'></button>}
        </div>
  )
}

export default MyInput