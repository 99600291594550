import React, { useEffect } from 'react'
import './userCardRoles.css';

const UserCardRoles = ({roles}) => {

    useEffect(() => {
        //alert('Создание UserCardRoles')
        //alert(roles)

    }, [] );

    //console.log(roles);
  return (
    <div>
        <h4>Роли (права доступа):</h4>
        <ul className='user_roles'>
        {Array.isArray(roles) && roles.map(role =>              
                    <li className={role.toLowerCase()} key={role}>{role}</li>
                )}
        </ul>
    </div>
  )
}

export default UserCardRoles